import React, { useState, useEffect, useCallback } from "react";
import { StaticQuery, graphql } from "gatsby";
import { useIsClient, useWindowSize } from "../../hooks";
import { Hamburger } from "../Hamburger";
import {
  GlobalHeaderStateProvider,
  useHeaderState,
} from "../Header/HeaderContext";
import { AnimatedLogo } from "./AnimatedLogo";
import { HeaderNav } from "../HeaderNav";
import { HeaderAboveNav } from "../HeaderAboveNav";
import "./Header.scss";

const HeaderComponent = ({
  data: {
    abovePrimaryNavigation: { items: aboveNavItems },
    primaryNavigation: { items: navItems },
    siteSettings: { options: siteOptions },
    wordpressWpSettings: { baseUrl },
  },
}) => {
  // get the state of the menu whether it is open or closed
  const {
    dispatch,
    state: { menuOpen },
  } = useHeaderState();

  const isClient = useIsClient();

  // closes the mobile menu if esc key is pressed
  const handleEscKey = useCallback(
    (event) =>
      event.keyCode === 27 &&
      menuOpen &&
      dispatch({ type: "setMenuOpen", data: false }),
    [menuOpen, dispatch]
  );

  const size = useWindowSize();

  // closes the mobile menu if esc key is pressed
  useEffect(() => {
    if (isClient) {
      document.addEventListener("keydown", handleEscKey, false);
      return () => {
        document.removeEventListener("keydown", handleEscKey);
      };
    }
  }, [handleEscKey, isClient]);

  // Check for window resize to see if menu needs to 'close' for desktop
  useEffect(() => {
    if (isClient && size.width > 1080 && menuOpen) {
      dispatch({
        type: "setMenuOpen",
        data: false,
      });
    }
  }, [isClient, size]);

  // handles whether there is a child menu item open on mobile
  const [openChild, setOpenChild] = useState(null);

  const onArrowClick = (newOpenChild) => {
    if (openChild === newOpenChild) return setOpenChild(null);
    return setOpenChild(newOpenChild);
  };

  return (
    <header className="header">
      <div
        className={`header-main layout ${menuOpen ? "header-main--open" : ""}`}
      >
        <div className="header-main__wrapper">
          <AnimatedLogo />
          <Hamburger />
          <div
            className={`header-main__navigation-wrapper ${
              menuOpen ? "header-main__navigation-wrapper--open" : ""
            } ${
              openChild !== null
                ? "header-main__navigation-wrapper--open-child"
                : ""
            }`}
          >
            <HeaderAboveNav aboveNavItems={aboveNavItems} />
            <HeaderNav
              navItems={navItems}
              onArrowClick={onArrowClick}
              openChild={openChild}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export function Header() {
  return (
    <StaticQuery
      query={graphql`
        query {
          abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "above-primary-navigation" }
          ) {
            items {
              title
              url
              classes
              object_id
              slug: object_slug
              target
              children: wordpress_children {
                title
                url
                classes
                object_id
                slug: object_slug
                target
              }
            }
          }
          primaryNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "primary-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
              object_id
              target
              children: wordpress_children {
                title
                url
                classes
                object_id
                slug: object_slug
                target
                # grandchildren: wordpress_children {
                #   title
                #   url
                #   classes
                #   object_id
                #   grandslug: object_slug
                #   target
                # }
              }
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showSearchIconInMainNavigation
              locations {
                phone
                addressRegion
              }
            }
          }
          wordpressWpSettings {
            siteUrl
          }
        }
      `}
      render={(data) => (
        <GlobalHeaderStateProvider>
          <HeaderComponent data={data} />
        </GlobalHeaderStateProvider>
      )}
    />
  );
}
