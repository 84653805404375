import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Logo.scss";

export const Logo = () => {
  return (
    <GatsbyLink to="/" className="logo-link" aria-label="Logo">
      <svg
        width="140"
        height="50"
        viewBox="0 0 140 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M34.9913 0.413574L22.2477 27.0017L9.43607 0.413574H0.00738525V39.6166H9.5744V20.9252L19.0117 39.572H25.4848L34.853 20.9252V49.5851H44.4274V0.413574H34.9913Z"
            fill="currentColor"
          />
          <path
            d="M78.658 49.5866L59.2337 19.4073V49.5866H49.6593V0.415039H58.2012L77.6255 30.525V10.0814H87.1999V49.5866H78.658Z"
            fill="currentColor"
          />
          <path
            d="M122.672 44.4065C120.888 46.2652 118.732 47.7245 116.346 48.6886C113.96 49.6526 111.397 50.0995 108.826 50C106.372 50.05 103.934 49.5981 101.66 48.6719C99.3865 47.7458 97.3247 46.3648 95.6008 44.6133C90.6419 39.6414 90.779 33.4943 90.779 24.9994C90.779 16.5057 90.6419 10.3586 95.6008 5.38674C97.3168 3.62464 99.3772 2.23669 101.653 1.30979C103.929 0.382888 106.371 -0.0630164 108.826 -6.55651e-07C120.191 -6.55651e-07 125.978 7.45845 127.287 15.7453H117.642C116.541 11.1189 113.717 8.56305 108.826 8.56305C107.676 8.53039 106.532 8.74816 105.473 9.20138C104.415 9.65459 103.467 10.3325 102.695 11.1883C100.836 13.26 100.353 15.5385 100.353 24.9994C100.353 34.4614 100.836 36.8093 102.695 38.881C103.472 39.7267 104.422 40.3932 105.481 40.8345C106.539 41.2757 107.681 41.4813 108.826 41.437C110.093 41.4835 111.355 41.2615 112.53 40.7854C113.705 40.3093 114.766 39.5899 115.645 38.6742C117.18 36.8451 117.968 34.5012 117.85 32.1136V30.2486H108.826V22.2379H127.355V29.4202C127.355 36.5332 126.115 40.8153 122.672 44.4065Z"
            fill="currentColor"
          />
          <path
            d="M9.57444 39.6182H0V49.3093H9.57444V39.6182Z"
            fill="#8DC63F"
          />
          <path
            d="M87.2011 0.414062H77.6254V10.0805H87.2011V0.414062Z"
            fill="#27AAE1"
          />
          <path d="M140 39.6172H130.155V49.5857H140V39.6172Z" fill="#F15A29" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="140" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </GatsbyLink>
  );
};
