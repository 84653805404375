import React, { useState, useEffect, useRef } from "react";
import GatsbyLink from "../../GatsbyLink";
import { useHeaderState } from "../../Header";
import { ArrowSvg } from "./ArrowSvg";
import { decodeEntities } from "../../../utils/helpers";

export const NavItem = ({
  item: { title, url, children, target, classes },
  index,
  onArrowClick,
  openChild,
}) => {
  // get the state of the menu whether it is open or closed
  const {
    dispatch,
    state: { menuOpen },
  } = useHeaderState();
  const el = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const mouseEnter = () => {
    setIsOpen(true);
  };

  const mouseOut = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const animationEl = el.current;
    if (window.innerWidth > 1080) {
      animationEl.addEventListener("mouseenter", mouseEnter);
      animationEl.addEventListener("mouseleave", mouseOut);
    }
    return () => {
      if (animationEl) {
        animationEl.removeEventListener("mouseenter", mouseEnter);
        animationEl.removeEventListener("mouseleave", mouseOut);
      }
    };
  }, []);

  const handleItemClick = () => {
    menuOpen && dispatch({ type: "setMenuOpen", data: false });
  };

  return (
    <li
      ref={el}
      className={`header-navigation__item ${classes} ${
        isOpen ? "header-navigation__item--open" : ""
      } ${openChild === index ? "header-navigation__item--openChild" : ""}`}
    >
      {/* If no children */}
      {!children && (
        <GatsbyLink
          to={url}
          target={target ? target : null}
          onClick={() => handleItemClick()}
          className="header-navigation__item__nochildren"
        >
          {decodeEntities(title)}
        </GatsbyLink>
      )}

      {/* If children, display parent link, arrow to navigate to child menu */}
      {children && children[0].url !== "#gatsby" && (
        <>
          <GatsbyLink
            to={url}
            target={target ? target : null}
            decode={true}
            onClick={() => handleItemClick()}
          >
            {decodeEntities(title)}
          </GatsbyLink>

          <button
            onClick={() => onArrowClick(index)}
            className="header-navigation__arrow"
          >
            <ArrowSvg />
          </button>
          <NavChildren
            childNav={children}
            isOpen={isOpen}
            handleItemClick={handleItemClick}
          />
        </>
      )}
    </li>
  );
};

const NavChildren = ({ childNav, isOpen = null, handleItemClick }) => {
  const [openGrandchild, setOpenGrandchild] = useState(null);

  const onArrowClick = (index) => {
    if (openGrandchild === index) return setOpenGrandchild(null);
    return setOpenGrandchild(index);
  };

  return (
    <ul
      className={`header-navigation__children ${
        openGrandchild !== null
          ? "header-navigation__children--openGrandchild"
          : ""
      }`}
    >
      {childNav.map((child, index) => {
        const { title, url, target, grandchildren, classes } = child;
        return (
          <li
            key={index}
            className={`header-navigation__childitem ${classes} ${
              openGrandchild === index
                ? "header-navigation__childitem--openGrandchild"
                : ""
            }`}
          >
            {/* If no children, then show the arrow but no child menu */}
            {!grandchildren && (
              <GatsbyLink
                to={url}
                target={target ? target : null}
                onClick={() => handleItemClick()}
                className="header-navigation__item__nochildren"
              >
                {decodeEntities(title)}
              </GatsbyLink>
            )}

            {/* If children, display parent link, arrow to navigate to child menu */}
            {grandchildren && grandchildren[0].url !== "#gatsby" && (
              <>
                <GatsbyLink
                  to={url}
                  target={target ? target : null}
                  decode={true}
                  onClick={() => handleItemClick()}
                >
                  {decodeEntities(title)}
                </GatsbyLink>
                <NavGrandChildren
                  childNav={grandchildren}
                  openGrandchild={openGrandchild === index ? "open" : "closed"}
                  handleItemClick={handleItemClick}
                />
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const NavGrandChildren = ({
  childNav,
  openGrandchild = null,
  handleItemClick,
}) => {
  return (
    <ul className={`header-navigation__grandchildren ${openGrandchild}`}>
      {childNav.map((child, index) => {
        const { title, url, target } = child;
        return (
          <li key={index} className="header-navigation__grandchilditem">
            <GatsbyLink
              to={url}
              target={target ? target : null}
              onClick={() => handleItemClick()}
              className="header-navigation__item__nochildren"
            >
              {decodeEntities(title)}
              <div className="header-navigation__arrow">
                <ArrowSvg />
              </div>
            </GatsbyLink>
          </li>
        );
      })}
    </ul>
  );
};
