import React, { useState, useEffect, useRef, useCallback } from "react";
import lottieAnimation from "./animation.json";
import GatsbyLink from "../../GatsbyLink";
import { Player } from "@lottiefiles/react-lottie-player";

export const AnimatedLogo = () => {
  const lottieRef = useRef(null);
  const el = useRef(null);

  const mouseEnter = useCallback(() => {
    lottieRef.current.setPlayerDirection("1");
    lottieRef.current.play();
  }, []);

  const mouseLeave = useCallback(() => {
    lottieRef.current.setPlayerDirection("-1");
    lottieRef.current.play();
  }, []);

  useEffect(() => {
    if (el.current) {
      const animationEl = el.current;
      animationEl.addEventListener("mouseenter", mouseEnter);
      animationEl.addEventListener("mouseleave", mouseLeave);
      return () => {
        if (animationEl) {
          animationEl.removeEventListener("mouseenter", mouseEnter);
          animationEl.removeEventListener("mouseleave", mouseLeave);
        }
      };
    }
  }, [mouseEnter, mouseLeave]);

  return (
    <GatsbyLink to="/" className="logo">
      <div ref={el}>
        <Player
          src={lottieAnimation}
          style={{ height: "60px", width: "160px" }}
          ref={lottieRef}
          keepLastFrame={true}
        ></Player>
      </div>
    </GatsbyLink>
  );
};
